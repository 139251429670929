import React from 'react'

import s from './Button.module.scss';

function Button(props) {

    const handleOnClick = () => {
        props.onClick();
    }

    return (
        <div className={`${s.button}`} style={{width:props.width}} onClick={handleOnClick}>{props.text}</div>
    )
}

export default Button
