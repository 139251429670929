import { createSelector } from 'reselect'

const selectCart = state => state.cart

export const selectCartItems = createSelector(
    [selectCart],
    cart => cart.cartItems
)

export const selectDeliveryStatus = createSelector(
    [selectCart],
    cart => cart.isDelivery
)

export const selectCartItemsCount = createSelector(
    [selectCartItems],
    cartItems =>
        (cartItems.reduce(
            (accumulatedQuantity, cartItem) =>
                accumulatedQuantity + cartItem.quantity,
            0
        )
        )
)

export const selectCartTotal = createSelector(
    [selectCartItems],
    cartItems =>
        (cartItems.reduce(
            (accumulatedQuantity, cartItem) =>
                accumulatedQuantity + cartItem.quantity * cartItem.price,
            0
        )
    )
)

export const selectCartWeightTotal = createSelector(
    [selectCartItems],
    cartItems => {
        var subTotal = 0;
        for(var index in cartItems){
            subTotal += (cartItems[index].quantity * cartItems[index].weight) 
        }
        return subTotal.toFixed(2)
    }
)

