import React from 'react'
import Lottie from "react-lottie"
import animationData from '../../../assets/animations/782-check-mark-success.json'

function CheckAnimation(props) {

    const defaultOptions = {
        loop:false,
        autoplay:true,
        animationData: animationData,
        // rendererSettings: {
        //     preserveAspectRatio: "xMidYMid slice"
        // }
    }

    return (
        <Lottie options={defaultOptions} height={props.height} width={props.width}></Lottie>
    )
}

export default CheckAnimation
