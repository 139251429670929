import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';

import { PersistGate} from 'redux-persist/integration/react'
import {store, persistor} from './redux/store';
import { Provider } from 'react-redux'
import {BrowserRouter, Switch, Route} from 'react-router-dom'

import MenuPage from './page/MenuPage'
import CheckoutPage from './page/checkout/CheckoutPage.component'
import SuccessOrderPage from './page/successorder/SuccessOrderPage.component'

require('dotenv').config();

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <PersistGate persistor={persistor}>
        <Switch>
          <Route path="/checkout" exact component={CheckoutPage}/>
          <Route path="/successorder" exact component={SuccessOrderPage}></Route>
          <Route path="/" component={MenuPage}/>
        </Switch>
      </PersistGate>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

