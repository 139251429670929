import React from 'react'
import ItemCard from './ItemCard'

import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect'
import { selectCartItems } from '../../redux/cart/cart.selectors'

import './menu.css'

function ItemList(props) {

    var itemCardListComponent = props.itemList.map((item, index) => (
        <div className={`col-xl-3 col-lg-4 col-md-6 col-12`} key={index}>
            <ItemCard item={item}></ItemCard>
        </div>
    ))

    return (
        <div>
            <div className="container-fluid item-list-container">
                <div className="row">
                    {itemCardListComponent}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    items : selectCartItems
})

export default connect(mapStateToProps,null)(ItemList)