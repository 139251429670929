import React , {useState, useEffect} from 'react'
import NavBar from '../components/menu/NavBar'
import ItemList from '../components/menu/ItemList'
import Footer from '../components/menu/Footer'
import '../components/menu/menu.css'

import axios from 'axios'

function Menu() {

    const [itemList, setItemList] = useState([])

    process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';

    useEffect(() => {

        // Update the document title using the browser API
        axios.get(process.env.REACT_APP_ITEM_GETALL_URL).then(
            res => {
                // ec2-api:
                //setItemList(res.data.items)
                //console.log(res.data)
                // lambda-api:
                setItemList(res.data)
            })
            .catch(error => {
                console.log("fetch get all error - ", error);
            })
      }, []);

    return (
        <div>
            <NavBar></NavBar>
            <ItemList itemList={itemList}></ItemList>
            <Footer></Footer>
        </div>
    )
}

export default Menu
