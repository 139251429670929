import CartActionTypes from './cart.types'

export const addItem = (item) => ({
    type: CartActionTypes.ADD_ITEM,
    payload: item
})

export const removeItem = item=>({
    type: CartActionTypes.REMOVE_ITEM,
    payload: item
})

export const setDelivery = (status) => ({
    type: CartActionTypes.SET_DELIVERY,
    payload:status
})

export const resetItem = () => ({
    type: CartActionTypes.RESET_ITEM
})