import React from "react";

import s from "./Confirmation.module.scss";

function Confirmation(props) {
	const handleAccept = () => {
		props.onAccept();
	};

	const handleCancel = () => {
		props.onCancel();
	};

	const Buttons = () => {
		if (props.isSubmit) {
			return <div>Please wait...</div>;
		} else {
			return (
				<div>
					<div
						className={`${s.confirm_button} btn btn-success`}
						onClick={handleAccept}
					>
						OK
					</div>
					<div
						className={`${s.confirm_button} btn btn-danger`}
						onClick={handleCancel}
					>
						Cancel
					</div>
				</div>
			);
		}
	};

	return (
		<div className={`${s.confirmation}`}>
			<p className={`${s.confirm_header}`}>Confirm Order</p>
			<p className={`${s.confirm_text}`}>
				Do you want to proceed with your order?
			</p>
			<div className={`d-flex justify-content-center`}>{Buttons()}</div>
		</div>
	);
}

export default Confirmation;
