import React from 'react'

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectCartItemsCount, selectCartTotal, selectCartWeightTotal } from '../../redux/cart/cart.selectors';
import { useHistory } from "react-router-dom";

import './menu.css'

function Footer(props) {

    let history = useHistory();
    let minScreenSize = 350;

    // Styling management:
    var primaryColor = "#6d6d6d";

    if(props.totalCount > 0){
        primaryColor = "#ee4d2d";
    }

    let footerCounterComponent = () =>{
        if(window.innerWidth > minScreenSize){
            return(
                <span className="float-left footer-text-left" style={{backgroundColor:primaryColor}}>{props.totalCount}</span>  
            )
        } else {
            return(
                null
            )
        }
    }

    const handleCheckoutBut = () => {
        if(props.totalCount > 0){
            history.push("/checkout")
        }
        
    }


    return (
        <footer className="fixed-bottom item-card-footer border-top">
            {footerCounterComponent()}
            <div className="d-flex p-2 float-right">
                <div className="container footer-container text-right">
                    <div className="row row-cols-1">
                        <div className="col">
                            <span className="footer-price-total-prefix">Total:</span> <span className="footer-price-total">RM{props.totalPrice}</span>
                        </div>
                        <div className="col">Weight: {props.totalWeight}kg</div>
                    </div>
                </div>
                <button type="button" className="btn item-footer-button" onClick={handleCheckoutBut} style={{backgroundColor:primaryColor}}>Check Out</button>
            </div>
        </footer>
    )
}

const mapStateToProps = createStructuredSelector({
    totalCount: selectCartItemsCount,
    totalPrice: selectCartTotal,
    totalWeight: selectCartWeightTotal
})

export default connect(mapStateToProps, null)(Footer);
