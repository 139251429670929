import React, { useState, useEffect } from "react";
import Form from "../../components/checkout/form/Form.component";
import Cardlist from "../../components/cardlist/Cardlist.component";
import Button from "../../components/common/button/Button.component";
import Popup from "reactjs-popup";
import Confirmation from "../../components/common/confirmation/Confirmation.component";

import axios from "axios";

import { connect } from "react-redux";
import {
	selectDeliveryStatus,
	selectCartItems,
} from "../../redux/cart/cart.selectors";
import { selectCustomerDetails } from "../../redux/customer/customer.selectors";
import { createStructuredSelector } from "reselect";

import { resetItem } from "../../redux/cart/cart.actions";
import { resetCustomer } from "../../redux/customer/customer.actions";

import s from "./CheckoutPage.module.scss";

import { useHistory } from "react-router-dom";

function CheckoutPage(props) {
	const history = useHistory();

	const [isFormNameValid, setIsFormNameValid] = useState(true);
	const [isFormPhoneNumValid, setIsFormPhoneNumValid] = useState(true);
	const [isFormAddressValid, setIsFormAddressValid] = useState(true);
	const [formNameError, setFormNameError] = useState();
	const [formPhoneNumError, setFormPhoneNumError] = useState();
	const [formAddressError, setFormAddressError] = useState();
	const [isSubmit, setIsSubmit] = useState(false);

	const [isOpen, setIsOpen] = useState(false);

	const handleBackButton = () => {
		history.goBack();
	};

	const handleSubmitButton = () => {
		// Perform state reset:
		setIsFormNameValid(true);
		setIsFormPhoneNumValid(true);
		setIsFormAddressValid(true);
		setFormNameError();
		setFormPhoneNumError();
		setFormAddressError();

		var isFormValid = true;
		var formEmptyErrMsg = "Please insert your ";

		if (props.customerDetails.name === "") {
			isFormValid &= false;
			setIsFormNameValid(false);
			setFormNameError(formEmptyErrMsg + "name.");
		}

		if (props.customerDetails.phone_number === "") {
			isFormValid &= false;
			setIsFormPhoneNumValid(false);
			setFormPhoneNumError(formEmptyErrMsg + "phone number.");
		} else {
			var regexPhoneNum = /^[0-9]{0,}$/;
			if (!regexPhoneNum.test(props.customerDetails.phone_number)) {
				isFormValid &= false;
				setIsFormPhoneNumValid(false);
				setFormPhoneNumError(
					"Phone number must only contain numbers and no space or special characters."
				);
			}
		}

		if (props.deliveryStatus) {
			if (props.customerDetails.address === "") {
				isFormValid &= false;
				setIsFormAddressValid(false);
				setFormAddressError(formEmptyErrMsg + "address.");
			}
		}

		if (isFormValid) {
			setIsSubmit(true);

			const requestBody = {
				name: props.customerDetails.name,
				phone_number: props.customerDetails.phone_number,
				address: props.customerDetails.address,
				is_delivery: props.deliveryStatus ? true : false,
				order_list: props.cartItems,
			};
			axios
				.post(process.env.REACT_APP_ADD_ORDER_URL, requestBody)
				.then((res) => {
					console.log("add succeed with msg - ", res);
					var userNameTmp = props.customerDetails.name;
					props.resetItem();
					props.resetCustomer();

					history.push({
						pathname: "/successorder",
						userName: userNameTmp,
					});
				})
				.catch((error) => {
					setIsSubmit(false);
					console.log("add item error - ", error);
				});
		} else {
			setIsOpen(false);
			window.scrollTo(0, 0);
		}
	};

	const handleOpen = () => {
		setIsOpen(true);
	};

	const handleConfirmCancel = () => {
		setIsOpen(false);
	};

	const contentStyle = { background: "#000" };
	const overlayStyle = { background: "rgba(0,0,0,0.5)" };
	const arrowStyle = { color: "#000" }; // style for an svg element
	const closeOnDocumentClick = true;
	const modal = true;

	const modalComponent = () => {
		return (
			<Popup
				trigger={
					<Button
						className={`${s.buttonSubmit}`}
						width="90%"
						text="Submit Order"
					></Button>
				}
				{...{
					modal,
					closeOnDocumentClick,
					contentStyle,
					overlayStyle,
					arrowStyle,
				}}
				open={isOpen}
				onOpen={handleOpen}
				lockScroll={true}
				disabled={isOpen}
			>
				<Confirmation
					onCancel={handleConfirmCancel}
					onAccept={handleSubmitButton}
					isSubmit={isSubmit}
				></Confirmation>
			</Popup>
		);
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className={`container-fluid ${s.checkoutpage}`}>
			<div className={`row align-items-center`}>
				<div className={`col-2`}>
					<button className={`btn ${s.backButton}`}>
						<i
							className="fa fa-arrow-circle-left fa-2x"
							style={{ color: "white" }}
							onClick={handleBackButton}
						></i>
					</button>
				</div>
				<div className={`col-8`}>
					<p className={`${s.header}`}>Checkout</p>
				</div>
				<div className={`col-2`}></div>
			</div>
			<div className={`row justify-content-center mb-3`}>
				<Form
					isFormNameValid={isFormNameValid}
					isFormPhoneNumValid={isFormPhoneNumValid}
					isFormAddressValid={isFormAddressValid}
					formNameError={formNameError}
					formPhoneNumError={formPhoneNumError}
					formAddressError={formAddressError}
				></Form>
			</div>
			<div className={`row justify-content-center mb-3`}>
				<Cardlist></Cardlist>
			</div>
			<div className={`row justify-content-center`}>
				{modalComponent()}
			</div>
		</div>
	);
}

const mapStateToProps = createStructuredSelector({
	deliveryStatus: selectDeliveryStatus,
	customerDetails: selectCustomerDetails,
	cartItems: selectCartItems,
});

const mapDispatchToProps = (dispatch) => ({
	resetItem: () => dispatch(resetItem()),
	resetCustomer: () => dispatch(resetCustomer()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPage);
