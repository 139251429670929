import CustomerActionTypes from './customer.types'

const INITIAL_STATE = {
    customerDetails: {
        name : '',
        phone_number: '',
        address: ''
    }
}

const customerReducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case CustomerActionTypes.ADD_CUSTOMER:
            return{
                ...state,
                customerDetails: action.payload
            }
        case CustomerActionTypes.RESET_CUSTOMER:
            return{
                ...state,
                customerDetails: {
                    name : '',
                    phone_number: '',
                    address: ''
                }
            }
        default:
            return state
    }
}

export default customerReducer