import {combineReducers} from 'redux'

import cartReducer from './cart/cart.reducers'
import customerReducer from './customer/customer.reducers'

import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
    key: 'test3',
    storage,
    whileList: ['cart', 'customer']
}

const rootReducer = combineReducers({
    cart : cartReducer,
    customer : customerReducer
})

export default persistReducer(persistConfig, rootReducer)