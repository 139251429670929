import React from 'react'

import s from './Summaryrow.module.scss'

function Summaryrow(props) {
    return (
        <div className={`row ${s.summaryrow}`} style={{fontWeight:props.fontWeight, fontSize:props.fontSize, color:props.color}}>
            <div className={`col-7`}>
                <p>{props.title}</p>
            </div>
            <div className={`col-5`}>
                <p className={`float-right`}>{props.description}</p>
            </div>
        </div>
    )
}

export default Summaryrow
