import React from 'react'
import Card from './card/Card.component'
import Summaryrow from './summaryrow/Summaryrow.component'

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectCartItems, selectCartTotal, selectDeliveryStatus} from '../../redux/cart/cart.selectors'

import {DELIVERYFEE} from '../../constants'

import s from './Cardlist.module.scss'

function Cardlist(props) {

    var deliveryPrice = (props.isDelivery) ? DELIVERYFEE : 0;

    var cardlistComponent = props.items.map((item,index) => (
        <Card
            item={item}
            key={index}
        ></Card>
    ))

    return (
        <div className={`${s.cardlist}`}>
            <p className={`${s.title}`}>Orders</p>
            <div className={`card ${s.card}`}>
                <div className={`card-body p-3 ${s.cardbody}`}>
                    <div className={`container-fluid mb-3 ${s.container_top}`}>
                        {cardlistComponent}
                    </div>
                    <div className={`container-fluid ${s.container_bottom}`}>
                        <Summaryrow
                            fontSize="14px"
                            title="Subtotal"
                            description={`RM ${props.totalPrice}`}
                        ></Summaryrow>
                        <Summaryrow
                            fontSize="14px"
                            title="Delivery fee"
                            description={`RM ${deliveryPrice}`}
                        ></Summaryrow>
                        <Summaryrow
                            fontWeight={600}
                            fontSize="20px"
                            title="Total"
                            color="black"
                            description={`RM ${props.totalPrice + deliveryPrice}`}
                        ></Summaryrow>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    items: selectCartItems,
    totalPrice: selectCartTotal,
    isDelivery: selectDeliveryStatus
})

export default connect(mapStateToProps, null)(Cardlist);