import React from 'react'
import s from './Card.module.scss'

function Card(props) {
    return (
        <div className={`card ${s.card}`}>
            <div className={`card-body ${s.body}`}>
                <div className={`container ${s.container}`}>
                    <div className={`row align-items-center ${s.row}`}>
                        <div className={`col-2 pl-0 ${s.col}`}>
                            <p className={`${s.quantity}`}>{props.item.quantity}</p>
                        </div>
                        <div className={`col-7 ${s.col}`}>
                            <p>{props.item.name}</p>
                        </div>
                        <div className={`col-3 pr-0 ${s.col}`}>
                            <p className={`float-right`}>RM {props.item.price}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Card
