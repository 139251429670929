import React, { useState } from "react";

import Popup from "reactjs-popup";
import PhotoCarousel from "../photocarousel/PhotoCarousel.component";

import "./common.css";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectCartItems } from "../../redux/cart/cart.selectors";

import "./menu.css";

import { addItem, removeItem } from "../../redux/cart/cart.actions";

function ItemCard(props) {
	const [isOpen, setIsOpen] = useState(false);

	var itemCount = 0;

	for (var index in props.items) {
		if (props.items[index].id === props.item.id) {
			itemCount = props.items[index].quantity;
			break;
		}
	}

	var ItemCounterComponent;

	const onCounterAddChange = () => {
		props.addItem(props.item);
	};

	const onCounterMinusChange = () => {
		if (itemCount > 0) {
			props.removeItem(props.item);
		}
	};

	const onCounterTextChange = (count) => {};

	const plusMinusComponent = () => {
		return (
			<div className="input-group item-button-input-group float-right">
				<button
					className="btn btn-danger"
					onClick={onCounterMinusChange}
				>
					-
				</button>
				<input
					type="text"
					name="quant[2]"
					className="form-control input-number text-center ml-1 mr-1"
					value={itemCount}
					min="0"
					max="100"
					disabled={true}
					onChange={onCounterTextChange}
				/>
				<button
					type="button"
					className="btn btn-success btn-number"
					onClick={onCounterAddChange}
				>
					+
				</button>
			</div>
		);
	};

	const plusComponent = () => {
		return (
			<button
				type="button"
				className="btn btn-success float-right"
				onClick={onCounterAddChange}
			>
				+ Add
			</button>
		);
	};

	if (itemCount === 0) {
		ItemCounterComponent = plusComponent;
	} else {
		ItemCounterComponent = plusMinusComponent;
	}

	const handleOpen = () => {
		setIsOpen(true);
	};

	const handleClose = () => {
		setIsOpen(false);
	};

	const contentStyle = { background: "#000" };
	const overlayStyle = { background: "rgba(0,0,0,0.5)" };
	const arrowStyle = { color: "#000" }; // style for an svg element
	const closeOnDocumentClick = true;
	const modal = true;

	const modalComponent = () => {
		// Retrieve default image if not available
		var coverImageUrl =
			props.item.images.length > 0
				? props.item.images[0].img_url
				: process.env.REACT_APP_DEFAULT_IMAGE_URL;
		var images =
			props.item.images.length > 0
				? props.item.images
				: [{ img_url: process.env.REACT_APP_DEFAULT_IMAGE_URL }];
		return (
			<Popup
				trigger={
					<img
						src={coverImageUrl}
						alt=""
						className="card-img-top item-image"
					/>
				}
				{...{
					modal,
					closeOnDocumentClick,
					contentStyle,
					overlayStyle,
					arrowStyle,
				}}
				open={isOpen}
				onOpen={handleOpen}
				lockScroll={true}
				disabled={isOpen}
			>
				<PhotoCarousel
					photoList={images}
					itemName={props.item.name}
					onClose={handleClose}
				></PhotoCarousel>
			</Popup>
		);
	};

	var weightDisplayText =
		props.item.weight >= 1
			? props.item.weight + " KG"
			: props.item.weight * 1000 + " G";

	return (
		<div className="card item-card mt-3 border-0">
			<div className="card-body pt-2 pb-2 pl-0 pr-0">
				<div className="container">
					<div className="row row-cols-2 card-price-row">
						<div className="col-4">{modalComponent()}</div>
						<div className="col-8">
							<h5 className="card-title item-title">
								{props.item.name}
							</h5>
							<div className="d-flex">
								<p className="item-text-header">Weight: </p>
								<p className="item-text-weight">
									{weightDisplayText}
								</p>
							</div>
							<div
								style={{
									marginRight: "80px",
									display: "block",
									overflow: "hidden",
									textOverflow: "ellipsis",
									wordWrap: "break-word",
									maxHeight: "1.8em",
									lineHeight: "0.9em",
								}}
							>
								<p className="item-text">
									{props.item.description}
								</p>
							</div>
						</div>
					</div>
					<div className="card-price">
						<div className="text-right mb-0">
							<span className="item-price-text-prefix">RM</span>{" "}
							<span className="item-price-text">
								{props.item.price}
							</span>
						</div>
						<ItemCounterComponent />
					</div>
				</div>
			</div>
		</div>
	);
}

const mapDispatchToProps = (dispatch) => ({
	addItem: (item) => dispatch(addItem(item)),
	removeItem: (item) => dispatch(removeItem(item)),
});

const mapStateToProps = createStructuredSelector({
	items: selectCartItems,
});

export default connect(mapStateToProps, mapDispatchToProps)(ItemCard);
