import React from 'react'
import CheckAnimation from '../../components/common/animation/CheckAnimation'
import { useHistory } from "react-router-dom";

import s from './SuccessOrderPage.module.scss'

function SuccessOrderPage(props) {
    const history = useHistory();

    const handleBut = () => {
        history.push('/')
    }

    return (
        <div className={`${s.successorder}`}>
            <div className={`container ${s.container}`}>
                <CheckAnimation height={"300px"} width={"300px"}></CheckAnimation>
                <p className={`${s.title}`}>Thank You!</p>
                <p className={`${s.message2}`}>{props.location.userName}</p>
                <p className={`${s.message1}`}>We have received your order.</p>
                <p className={`${s.message2}`}>Please stay tuned as a whatsapp message will be sent to you shortly!</p>
                <button className={`btn btn-primary ${s.button}`} onClick={handleBut}>Continue Shopping</button>
            </div>
        </div>
    )
}

export default SuccessOrderPage
