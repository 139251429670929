import React from 'react'
import './menu.css'

function NavBar() {
    return (
        <div className="container-fluid navbar-seafood">
          <p className="navbar-brand">Longevity Seafood</p>
        </div>
    )
}

export default NavBar
