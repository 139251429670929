import React from 'react'
import { connect } from 'react-redux';

import s from './Form.module.scss'

import { setDelivery } from '../../../redux/cart/cart.actions';
import { addCustomer } from '../../../redux/customer/customer.actions';

import { selectDeliveryStatus } from '../../../redux/cart/cart.selectors'
import { selectCustomerDetails } from '../../../redux/customer/customer.selectors'

import { createStructuredSelector } from 'reselect';

function Form(props) {

    const handleCheckDeliveryChange = () => {
        props.setDelivery(true);
    }

    const handleCheckPickChange = () => {
        props.setDelivery(false);
    }

    const handleNameChange = (e) => {
        props.addCustomer({...props.customerDetails, name : e.target.value});
    }

    const handlePhoneNumberChange = (e) => {
        props.addCustomer({...props.customerDetails, phone_number : e.target.value});
    }

    const handleAddressChange = (e) => {
        props.addCustomer({...props.customerDetails, address : e.target.value});
    }

    var colorRed = "#ea1548"
    var colorBlack = "#848181"

    var colorName = colorBlack;
    var colorPhoneNum = colorBlack;
    var colorAdress = colorBlack;

    var warningNameComponent = () => {return(null)}
    var warningPhoneNumComponent = () => {return(null)}
    var warningAddressComponent = () => {return(null)}

    if(!props.isFormPhoneNumValid){
        colorPhoneNum = colorRed;
        warningPhoneNumComponent = () => {
            return(
                <p style={{color:colorRed, fontSize:'13px'}}>{props.formPhoneNumError}</p>
            )
        }
    }
    if(!props.isFormNameValid){
        colorName = colorRed;
        warningNameComponent = () => {
            return(
                <p style={{color:colorRed, fontSize:'13px'}}>{props.formNameError}</p>
            )
        }
    }

    if(!props.isFormAddressValid){
        colorAdress = colorRed;
        warningAddressComponent = () => {
            return(
                <p style={{color:colorRed, fontSize:'13px'}}>{props.formAddressError}</p>
            )
        }
    }

    var deliveryAddInputComponent = () => {
        if(props.deliveryStatus){
            return(
                <div className="form-group mb-0">
                    <input type="text" className="form-control" style={{borderColor:colorAdress}} placeholder="Enter Address" onChange={handleAddressChange} value={props.customerDetails.address}/>
                    {warningAddressComponent()}
                </div>
            )
        } else {
            return(
                <div className="form-group mb-0">
                    <input type="text" className="form-control" disabled={true} placeholder="Enter Address" onChange={handleAddressChange} value={props.customerDetails.address}/>
                </div>
            )
        }
    }

    return (
        <div className={`${s.form}`}>
            <p className={`${s.title}`}>Profile Details</p>
            <div className={`card`}>
                <div className={`card-body`}>
                    <form>
                        <div className="form-group">
                            <label className={`mb-1`} style={{color:colorName}}>Name</label>
                            <input type="text" className="form-control" style={{borderColor:colorName}} placeholder="Enter Name" onChange={handleNameChange} value={props.customerDetails.name}/>
                            {warningNameComponent()}
                        </div>
                        <div className="form-group">
                            <label className={`mb-1`} style={{color:colorPhoneNum}}>Phone Number</label>
                            <input type="text" className="form-control" style={{borderColor:colorPhoneNum}} placeholder="Enter Phone Number" onChange={handlePhoneNumberChange} value={props.customerDetails.phone_number}/>
                            {warningPhoneNumComponent()}
                        </div>
                        <div className="form-check mb-1">
                            <div className="form-check pl-0">
                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" onChange={handleCheckDeliveryChange} checked={props.deliveryStatus}/>
                                <label className="form-check-label">
                                    Delivery
                                </label>
                            </div>
                            <div className="form-check pl-0">
                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" onChange={handleCheckPickChange} checked={!props.deliveryStatus}/>
                                <label className="form-check-label">
                                    Self pick up
                                </label>
                            </div>
                        </div>
                        {deliveryAddInputComponent()}
                    </form>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = dispatch => ({
    setDelivery: (status) => dispatch(setDelivery(status)),
    addCustomer: (customerDetails) => dispatch(addCustomer(customerDetails))
})

const mapStateToProps = createStructuredSelector({
    deliveryStatus: selectDeliveryStatus,
    customerDetails: selectCustomerDetails
})

export default connect(mapStateToProps, mapDispatchToProps)(Form)
