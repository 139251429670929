import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";

// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

import s from './PhotoCarousel.module.scss'
import './PhotoCarousel.css'

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

function PhotoCarousel(props) {

    const handleCloseButton = () => {
        props.onClose();
    }

    var carouselComponent = props.photoList.map((photo,index) => (
        <SwiperSlide key={index}>
            <img className={`${s.image}`} src={photo.img_url} alt={""}></img>
        </SwiperSlide>
    ))

    return (
        <div className={`${s.photocarousel}`}>
            <div className={`d-flex justify-content-between align-items-center ${s.topbar}`}>
                <span className={`${s.topbartext}`}>{props.itemName}</span>
                <div className={`${s.closebutton}`}><i className="fa fa-times" style={{color:"#ee4d2d",fontSize:'50px'}} onClick={handleCloseButton}></i></div>
            </div>
            <Swiper
            spaceBetween={50}
            slidesPerView={1}
            navigation
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            >
                {carouselComponent}
            </Swiper>
        </div>
    )
}

export default PhotoCarousel
